<template>
  <div class="page" style="box-sizing: border-box; margin-top: 46px;">
    <van-nav-bar title="新增用户" fixed left-arrow @click-left="$router.back()"></van-nav-bar>
    <div style="height: calc(100% - 46px);overflow: scroll">
      <van-form>
        <van-field label="账号" name="username" required v-model="form.username" placeholder="请输入登录账号" autocomplete="new-username"></van-field>
        <van-field label="密码" name="password" required v-model="form.password" type="password" placeholder="请输入登陆密码" autocomplete="new-password"></van-field>
      </van-form>
      <van-divider></van-divider>
      <div style="margin: 8px 16px;font-size: 14px;color: gray">账号角色</div>
      <van-checkbox-group v-model="result">
        <van-cell-group>
          <van-cell
              v-for="(item, index) in roleList"
              clickable
              :key="item.id"
              :title="item.name"
              @click="toggle(index)"
          >
            <template #right-icon>
              <van-checkbox :name="item.id" ref="checkboxes" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>

      <van-button type="primary" block style="width: 80%;margin: 28px auto;" round @click="submitForm">提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "addUser",
  data() {
    return {
      form: {username: '', password: ''},
      roleList: [],
      result: [],
    }
  },
  created() {
    this.loadAllRoles()
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    async loadAllRoles() {
      let res = await this.$http.get('role')
      if (res.code === 200) {
        this.roleList = res.data
      }
    },
    submitForm() {
      if (this.form.username === '' || this.form.password === '') {
        this.$toast.fail('账号密码缺一不可')
        return
      }
      this.form.roleList = this.result
      this.$http.post('user', this.form).then(res => {
        if (res.code === 200) {
          this.$toast.success('添加成功')
          setTimeout(() => {this.$router.back()}, 1500)
        } else {
          this.$toast.fail(res.msg)
        }
      }).catch(err => {
        this.$toast.fail(err.toLocaleString())
      })
    }
  }
}
</script>

<style scoped>

</style>
